import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A `}<strong parentName="p">{`Collective Conviction`}</strong>{` is a `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{` that is held by a large portion of the population, creating an impossibly strong `}<a parentName="p" {...{
        "href": "/Fixed%20Truth",
        "title": "Fixed Truth"
      }}>{`Fixed Truth`}</a>{` that may last for as long as the Collective Conviction is held by a large number of `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Threads`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      